import { getConfig } from '@lib/prepr/config';
import { fetchHomePage } from '@lib/prepr/home';
import HomeTemplate from '@templates/HomeTemplate/HomeTemplate';
import { Locale } from '@type-declarations/locale';
import { HomePage as Props } from '@type-declarations/page';

export default function HomePage({ page }: { page: Props }) {
  return <HomeTemplate page={page} />;
}

export async function getServerSideProps({
  req,
  locale,
  draftMode: preview = false,
}: {
  req: { cookies: { __prepr_uid: string } };
  locale: Locale;
  draftMode: boolean;
}) {
  const { __prepr_uid: customerId = '' } = req.cookies;
  const [page, config] = await Promise.all([
    fetchHomePage({ locale, customerId, preview }),
    getConfig({ locale, preview }),
  ]);

  if (!page) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      locale,
      preview,
      page,
      config,
    },
  };
}
