import {
  Article,
  BreadcrumbList,
  Event,
  JobPosting,
  Organization,
} from 'schema-dts';

export default function StructuredData({
  schema,
}: {
  schema: Organization | Event | Article | BreadcrumbList | JobPosting;
}) {
  if (!schema) return null;

  // Cast the schema, so we can add the context, this is necessary for Google
  const unknownSchema = schema as unknown;
  const castedSchema = unknownSchema as Record<string, unknown>;
  castedSchema['@context'] = 'https://schema.org';

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(castedSchema) }}
    />
  );
}
