import StructuredData from '@atoms/StructuredData/StructuredData';
import ContentHero from '@organisms/ContentHero/ContentHero';
import Sections from '@organisms/Sections/Sections';
import type { HomePage as Props } from '@type-declarations/page';
import { getOrganisationStructuredData } from '@utils/getStructuredData';

function HomeTemplate({ page }: { page: Props }) {
  const { title, hero, sections } = page;

  return (
    <>
      <ContentHero
        shortTitle={title}
        longTitle={hero?.title}
        text={hero?.text}
        image={hero?.image}
        focusPoint={hero?.focusPoint}
        cta={hero?.cta}
      />

      <Sections sections={sections} />

      <StructuredData schema={getOrganisationStructuredData()} />
    </>
  );
}

export default HomeTemplate;
